import { makeStyles } from "@mui/styles";

const AppRadioButtonStyles = makeStyles(() => ({
  default: () => ({}),
  separated: () => ({
    "& .MuiFormControlLabel-root": {
      border: "2px solid #b8b8b8",
      padding: 0,
      margin: 0,
      width: "fit-content",
      minWidth: "10rem",
      borderRadius: "0.3rem",
      backgroundColor: "#000000",
    },
    "& .MuiFormLabel-root": {
      color: "black",
    },
    "& .MuiFormGroup-root ": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      gap: "1rem",
    },
  }),
}));
export { AppRadioButtonStyles };
