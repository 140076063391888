import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect } from "react";
import { t } from "i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { NexiChargeTransactionDetails } from "../../@types/Booking/nexiTransactionDetails";
import { useTranslation } from "react-i18next";

export function WireTransferConfirm() {
  const { t: translate } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dbId = searchParams.get("dbId");
  return (
    <section className="min-h-[80vh] box-border px-44  flex flex-col border-t-2 border-solid border-grey-300">
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        {dbId == "undefined" ? (
          <img src="/assets/images/close.png" className="h-23 w-18" />
        ) : (
          <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        )}
        {dbId == "undefined" ? (
          <h1 className="text-3xl font-bold text-[#2267ae] py-2">
            {translate("Your Booking Is not created")}
          </h1>
        ) : (
          <h1 className="text-3xl font-bold text-[#2267ae] py-2">
            {translate("Congratulations! Your Booking Is Completed")}
          </h1>
        )}
        <p className="font-bold text-black-600">
          {/* Reservation Code:{" "} */}
          {/* Web Check In Code:{" "}
            <span className="font-bold text-[#5cbc14]">DG-87450</span> */}
        </p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            variant="outlined"
            color="addInsuranceButton"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-8"}
            color="addInsuranceButton"
            variant="outlined"
            style={{ borderRadius: "5" }}
          >
            {/* Go to Account */}
            <span className="normal-case text-[#2267ae] p-1 text-lg">
              {" "}
              Dashboard
            </span>
          </AppStandardButton>
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
