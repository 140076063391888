import { CloseTwoTone, DoneTwoTone } from "@mui/icons-material";
import { formatPriceWithCurrencySymbol } from "../../../helpers/getPriceWithCurrencySymbol";
import BookingGetters from "../../../store/onlineBooking/getters";
import { AppStandardButton as AppButton } from "../../UI/AppButtons";
import { InsuranceCardProps } from "./Insurance.props";
import React, { useState } from "react";
import { t } from "i18next";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

function InsuranceCardsLayout({
  insurances,
  onSelected,
  selectedInsuranceIndex = -1,
}: InsuranceCardProps) {
  const allInsuranceFeatures: Record<string, boolean> = {};
  const allInsurances = insurances.map((el) => {
    const availableInsurances = el.features.reduce((prev, current) => {
      const feature = current.toLowerCase();
      prev[feature] = true;
      allInsuranceFeatures[feature] = true;
      return prev;
    }, {});
    return { ...el, availableInsurances };
  });
  const isSmallScreen = window.innerWidth <= 768;
  const currencyDetails = BookingGetters.getCurrency();
  const boxShadow = "shadow-lg";
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const border = " border-[0.5px] border-solid border-slate-600 ";
  const handleCardClick = (index: number) => {
    if (expandedIndex == index) {
      setExpandedIndex(-1);
      onSelected(-1);
    } else {
      setExpandedIndex(index);
      onSelected(index);
    }
  };
  const secondaryTextColor = " text-blue-600 ";

  const [expandedCardIndex, setExpandedCardIndex] = useState<number[]>([]);
  const showInsuranceDetails = (value: number) => {
    const index = expandedCardIndex.indexOf(value);
    if (index !== -1) {
      setExpandedCardIndex(expandedCardIndex.filter((item) => item !== value));
    } else {
      setExpandedCardIndex([...expandedCardIndex, value]);
    }
  };
  return isSmallScreen ? (
    <div className="flex flex-wrap justify-center items-center text-black w-[125%] -ml-[12%]">
      {allInsurances.map((el, index) => (
        <div
          className={`${boxShadow}  m-8 p-8 cursor-pointer transition-transform duration-300 flex-1 flex flex-col justify-center items-center
            hover:-translate-y-2 rounded-3xl bg-white`}
          key={index}
        >
          <button onClick={() => showInsuranceDetails(index)}>
            {expandedCardIndex.includes(index) ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </button>
          <h5 className="text-xl font-semibold text-center mb-2">{el.name}</h5>
          <p className={`text-lg mb-2 font-bold`}>
            {formatPriceWithCurrencySymbol(currencyDetails).symbol + " "}
            {el.pricePerDayInUSD}
          </p>

          <div
            className="text-center"
            style={
              expandedCardIndex.includes(index)
                ? { display: "block" }
                : { display: "none" }
            }
          >
            {Object.keys(allInsuranceFeatures).map((feature, i) => (
              <div key={i} className={`items-center mb-2`}>
                <div className={"flex"}>
                  {feature.trim() !== "" &&
                    (el.availableInsurances[feature] ? (
                      <DoneTwoTone className="text-green-800 font-bold mr-2" />
                    ) : (
                      <CloseTwoTone className="text-red-800 mr-2" />
                    ))}

                  <div
                    className="text-md"
                    style={{ textAlign: "left", fontWeight: "500" }}
                  >
                    {feature}
                  </div>
                </div>
                {el.bookingText &&
                  el.bookingText[
                    feature.toUpperCase() + ":" + el.name.toUpperCase()
                  ] && (
                    <div>
                      <p
                        style={{
                          textAlign: "left",
                          fontSize: "small",
                          marginLeft: "17%",
                        }}
                      >
                        {
                          el?.bookingText[
                            feature.toUpperCase() + ":" + el.name.toUpperCase()
                          ]
                        }
                      </p>
                    </div>
                  )}
              </div>
            ))}
          </div>
          <AppButton
            className="h-10 w-31 "
            color={selectedInsuranceIndex == index ? "success" : "primary"}
            onClick={() => handleCardClick(index)}
            style={{ borderRadius: "5", marginTop: "9px" }}
          >
            <p className={`w-full text-center`}>{t("Select")}</p>
          </AppButton>
        </div>
      ))}
    </div>
  ) : (
    <table
      className="table-auto border-collapse text-black sticky-header sm:w-full"
      style={{ height: "50px", maxHeight: "50px" }}
    >
      <thead
        style={{
          left: "0px",
          top: "113px",
          zIndex: 3,
        }}
      >
        <tr
          className={border}
          style={{
            position: "sticky",
            left: "0px",
            top: "113px",
            zIndex: 3,
          }}
        >
          <th
            className={border}
            style={{
              position: "sticky",
              // left: "0px",
              top: "113px",
              zIndex: 4,
              background: "white",
            }}
          >
            <p>Tabella dei prezzi</p>
          </th>

          {allInsurances.map((el, index) => (
            <th
              className={`${border} sm:min-w-auto h-20  sm:relative sticky-header`}
              key={index}
              style={{
                position: "sticky",
                top: "113px",
                zIndex: 3,
                background: "white",
                minWidth: "120px", // Set a minimum width to prevent collapsing
                maxWidth: "200px", // Set a maximum width to limit expansion
                whiteSpace: "nowrap",
                padding: "10px", // Adjust padding for spacing
              }}
            >
              {el.isRecommended && (
                <div className="text-grey-200 font-light">
                  <img
                    src="/assets/images/Favo-icon@2x.png"
                    className="h-12 w-12 top-0 absolute"
                    alt="Favorite"
                  />
                </div>
              )}
              <div
                className="sticky-header"
                style={{
                  whiteSpace: "pre-line",
                }}
                dangerouslySetInnerHTML={{ __html: el.name }}
              />
              <p
                className={`${secondaryTextColor} sm:text-md font-normal sticky-header`}
              >
                {formatPriceWithCurrencySymbol(BookingGetters.getCurrency())
                  .symbol + " "}
                {el.pricePerDayInUSD}
              </p>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="overflow-scroll w-full">
        {Object.keys(allInsuranceFeatures).map((el, index) => {
          return (
            <>
              <tr
                key={index}
                className={
                  border + " sm:font-semibold text-sm capitalize text-center"
                }
              >
                <td
                  className={border + " sm:min-w-[12rem] box-border p-1"}
                  style={{
                    position: "sticky",
                    left: "0px",
                    zIndex: 1,
                    background: "white",
                  }}
                >
                  <div
                    style={{ whiteSpace: "pre-line", fontSize: "small" }}
                    dangerouslySetInnerHTML={{ __html: el }}
                  />
                </td>

                {allInsurances.map((insurance, i) => {
                  const text =
                    insurance.bookingText &&
                    insurance.bookingText[
                      el.toUpperCase() + ":" + insurance.name.toUpperCase()
                    ];
                  if (text != null && text !== "undefined" && text !== "") {
                    return (
                      <td className={border + ""} key={i}>
                        <div
                          style={{ whiteSpace: "pre-line", fontSize: "small" }}
                          dangerouslySetInnerHTML={{ __html: text }}
                        />
                      </td>
                    );
                  } else if (
                    insurance.availableInsurances &&
                    insurance.availableInsurances[el]
                  ) {
                    return (
                      <td className={border + ""} key={i}>
                        <p className="h-full w-full flex justify-center items-center">
                          <DoneTwoTone className="text-green-800 font-bold" />
                        </p>
                      </td>
                    );
                  } else {
                    return (
                      <td className={border} key={i}>
                        <p className="h-full w-full flex justify-center items-center">
                          <CloseTwoTone className="text-red-800" />
                        </p>
                      </td>
                    );
                  }
                })}
              </tr>
            </>
          );
        })}
      </tbody>
      <tfoot className="">
        <tr>
          <td
            className={border + "h-20"}
            style={{
              position: "sticky",
              bottom: "30px",
              zIndex: "1",
              background: "white",
              color: "gray",
            }}
          >
            <AppButton
              clickHandler={() => {
                onSelected(-1);
              }}
              color="red"
              className="box-border px-2 text-white"
            >
              {/* Cancel */}
              {t("Cancel")}
            </AppButton>
          </td>
          {allInsurances.map((el, index) => (
            <td
              className={border + "relative"}
              key={index}
              style={{
                position: "sticky",
                bottom: "30px",
                zIndex: "1",
                background: "white",
                color: "gray",
              }}
            >
              <AppButton
                color={selectedInsuranceIndex == index ? "success" : "primary"}
                clickHandler={() => {
                  onSelected(index);
                }}
                className="box-border px-4"
              >
                {/* Choose */}
                {t("Choose")}
              </AppButton>
            </td>
          ))}
        </tr>
      </tfoot>
    </table>
  );
}
export { InsuranceCardsLayout };
