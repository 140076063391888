import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
  IndividualFormProps,
} from "../../components/Layouts/WebCheckInForms/WebCheckInForms.props";
import React, { useEffect, useRef, useState } from "react";

import AppButton from "../../components/UI/AppButtons/AppButton";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import { AppRadioButton } from "../../components/UI/AppRadioButtons/AppRadioButtons";
import { AppSecondaryNavigation } from "../../@types/AppSecondaryNavigation";
import AppStandardButton from "../../components/UI/AppButtons";
import { AppStandardMultiSelectCheckBox } from "../../components/UI/AppMultipleSelection/";
import { BOOKING_ACTIONS } from "../../store/onlineBooking";
import BookingGetters from "../../store/onlineBooking/getters";
import { BreadCrumbs } from "../../components/UI/AppBreadCrumbs";
import { CustomerDetailFormField } from "./UserDetailsForm";
import { KeyboardArrowRight } from "@mui/icons-material";
import { Modal } from "../../components/UI/AppModal";
import { ReservationDetailsPreview } from "../../components/Layouts/ReservationDetailsPreview/ReservationDetailsPreview";
import SecondaryNavConfig from "../../API/responses/SecondaryNavConfig.json";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
// import { useWebCheckInForm } from "../../hooks/WebCheckInForm_2";
import { AllPaymentMethodsResponse } from "../../@types/DTO/AllPaymentMethods";
import { PayPal } from "../../components/Layouts/PaymentForms/PayPal";
import { createSearchParams, useNavigate } from "react-router-dom";
import BookingApi from "../../API/Apis/BookingApi";
import { Vehicle } from "../../@types/DTO/Vehicles";
import { SelectedVehicleDetails } from "../../@types/Booking/SelectedVehicleDetails";
//import { Nexi } from "../../components/Layouts/PaymentForms/Nexi";
import { t } from "i18next";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
// import { CompanyInvoice } from "../../components/Layouts/PaymentForms/CompanyInvoice";
import {
  CompanyInvoiceFormFields,
  ReadOnlyIfInitialValuesNotProvided,
  WebCheckInFormFields,
} from "../../components/Layouts/WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form";
import { FormFieldErrorMessage } from "../../components/UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { SelfDriverFormFields } from "../../components/Layouts/WebCheckInForms/FormKeys";
import { AppInputSwitchProps } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher.props";
import { object } from "prop-types";
import { BookingDetails } from "../../@types/Booking/bookingDetails";
import { companyDetails } from "../../@types/Booking/companyDetails";
import { LoadingButton } from "@mui/lab";
import { ReservationSuccessfullResponse } from "../../@types/DTO/ApiResponses";
import TagManager from "react-gtm-module";
import { BookingStateRedux } from "../../store/onlineBooking/states";
//import { GTM } from "../../config/googleTagManager";
import { BookingConfirmPage } from "../bookingConfirmation";
import { SSNFormPay } from "../../hooks/SSNFormPay";
//GTM;
function PaymentDetailsPage({
  values = {},
  initialValues = { ...DefaultValuesDetailsForm },
  onSubmit = () => {},
  onChange = () => {},
  children = <></>,
}: IndividualFormProps): JSX.Element {
  const [
    {
      setValue,
      getValues,

      register,
      handleSubmit,
      formState: { errors },
    },
    individualFormFields,
  ] = SSNFormPay(
    CompanyInvoiceFormFields,
    {
      ...Object.keys(values).reduce((prevValue, currentKey) => {
        prevValue[currentKey] =
          prevValue[currentKey] || initialValues[currentKey];
        return prevValue;
      }, values),
    },
    onChange
  );
  const { t: translate } = useTranslation();
  const [companyinvoiceDetails, setCompanyinvoiceDetails] = useState<any>([]);
  const currentLoggedInuser: string | undefined =
    localStorage.getItem("userID") || undefined;
  const navigateTo = useNavigate();
  const dispatch = useDispatch();
  const payPalFormRef = useRef<HTMLFormElement>();
  const AppSettings = BookingGetters.getAppSettings();
  const [checked, setChecked] = React.useState(false);
  const inputTAXRef = useRef<HTMLInputElement>(null);
  const inputVATRef = useRef<HTMLInputElement>(null);
  const [taxVal, setTaxVal] = useState("9999999999999999");
  const [isReadonly, setIsReadonly] = useState(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    console.log(checked);
  };
  const nexiFormRef = useRef<HTMLFormElement>();
  const [allPaymentMethods, setAllPaymentMethods] = useState<
    Partial<AllPaymentMethodsResponse>
  >({});
  const ReservationDetails = BookingGetters.getBookingDetails();
  const [ReservationId, setReservationId] = useState<number>(0);
  const VehicleDetails = BookingGetters.getSelectedVehicleDetails();
  const UserDetails = BookingGetters.getUserDetails();
  useEffect(() => {
    BookingApi.getPrivacyAndTerms().then((e) => {
      dispatch(BOOKING_ACTIONS.setRentalTerms(e.data.conditionText));
    });
  }, []);

  const [userDetails, setUserDetails] = useState<DetailsFormValues>({
    ...DefaultValuesDetailsForm,
  });
  const handleCountryChange = () => {
    formStates.setValue("city", "");
    formStates.setValue("state", "");
  };
  const [whetherIfGroupIsOnRequest, setWhetherIfGroupIsOnRequest] =
    useState<string>("false");
  useEffect(() => {
    if (VehicleDetails.vehicleDetails?.Status.toLowerCase() == "onrequest") {
      setWhetherIfGroupIsOnRequest("true");
    } else setWhetherIfGroupIsOnRequest("false");
  });

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>("");
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(BOOKING_ACTIONS.setUserDetails(userDetails));
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [userDetails]);

  const [formStates, formFields] = SSNFormPay(
    CustomerDetailFormField,
    userDetails,
    (e) => {
      setUserDetails({ ...userDetails, ...e });
    }
  );

  const [loading, setLoading] = useState(false);
  const blurScreen = () => {
    document.body.style.opacity = String(0.5);
    document.body.style.pointerEvents = "none";
  };
  useEffect(() => {
    if (loading) {
      blurScreen();
    } else {
      document.body.style.opacity = String(1);
      document.body.style.pointerEvents = "";
    }
  }, [loading]);

  function requestParentResize(checked) {
    let iframeHeight;
    if (checked) {
      if (userType === "Company") {
        iframeHeight = 2622;
      } else {
        iframeHeight = 2910;
      }
    } else {
      iframeHeight = 2212;
    }
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }
  const [userType, setUserType] = useState("Individual");

  // ******  logic added for percentage of the total amount that a customer is required to pay at the time of creating a booking ******
  const [percentOnAmount] = useState(AppSettings?.percentOnAmount);
  const [updatedPrice, setUpdatedPrice] = useState(
    VehicleDetails?.paymentDetails?.price
  );
  useEffect(() => {
    const price = VehicleDetails?.paymentDetails?.price;
    if (percentOnAmount != null && price != null) {
      const updatedPrice = (price ? price * percentOnAmount : 0) / 100;
      setUpdatedPrice(updatedPrice);
    }
  }, [percentOnAmount]);
  // ------------ logic end -----------------

  useEffect(() => {
    requestParentResize(checked);
  }, [checked, userType]);
  const handleUserType = (userType) => {
    setUserType(userType);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!VehicleDetails.paymentDetails?.price) {
        navigateTo("/");
      }
    }, 1000);
    BookingApi.getAllPaymentMethods().then((e) => {
      if (VehicleDetails.paymentDetails?.type == "PAYNOW") {
        delete e.wireTransfer;
      }
      setAllPaymentMethods(e);
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const searchParams: string = createSearchParams({
    dropOffDateAndTime: new Date(ReservationDetails.dropOffDateAndTime)
      .getTime()
      .toString(),
    pickUpDateAndTime: new Date(ReservationDetails.pickUpDateAndTime)
      .getTime()
      .toString(),
    pickUpLocation: ReservationDetails.pickUpLocationDetails.code,
    dropOffLocation: ReservationDetails.dropOffLocationDetails.code,
    reservationSource: ReservationDetails.reservationSource.code,
    macroGroupName: ReservationDetails.macroGroupName,
    discountValueWithoutVat: ReservationDetails.discountValueWithoutVat,
    agreementCoupon: ReservationDetails.agreementCoupon,
    showPics: "true",
    showOptionalImage: "true",
    showVehicleParameter: "true",
    showVehicleExtraImage: "true",
    showBookingDiscount: "true",
    isYoungDriverAge: ReservationDetails.isYoungDriverAge,
    isSeniorDriverAge: ReservationDetails.isSeniorDriverAge,
    noFeeAge: ReservationDetails.noFeeAge,
  }).toString();
  const options: Array<AppSecondaryNavigation> = [
    {
      to: "/embed",
      label: t(SecondaryNavConfig.Search),
    },
    {
      to: "/choose/vehicle?" + searchParams,
      label: t(SecondaryNavConfig.Choose),
    },
    {
      to: "#",
      label: t(SecondaryNavConfig.reserveAndPay),
      isActive: true,
    },
  ];
  const isSmallScreen = window.innerWidth <= 768;
  return (
    <section style={isSmallScreen ? { width: "99%", overflow: "hidden" } : {}}>
      <div className="flex justify-center items-center"></div>

      <div className="bg-white w-full box-border px-8">
        <BreadCrumbs separator={<KeyboardArrowRight />} elements={options} />
      </div>
      <div className="grow flex flex-col grow gap-16 box-border p-8 bg-gray-100">
        <div className="bg-white box-border p-8">
          {" "}
          <ReservationDetailsPreview></ReservationDetailsPreview>
        </div>
      </div>
      {/* <p className="text-black">{JSON.stringify(userDetails)}</p> */}
      <div className="min-h-screen flex flex-row">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            //   setIsShowingPreview(true);
            // }}
            // onClick={() => {
            setLoading(true);
            setIsSaving(true);
            BookingApi.createBooking(
              ReservationDetails,
              UserDetails,
              VehicleDetails.vehicleDetails as Vehicle,
              VehicleDetails.paymentDetails as SelectedVehicleDetails,
              selectedPaymentMethod,
              companyinvoiceDetails,
              currentLoggedInuser,
              whetherIfGroupIsOnRequest,
              userType,
              checked
            ).then((e) => {
              setIsSaving(false);
              setLoading(false);
              // setDiscountValueWithoutVat(vehicleDetails?.Reference.ID);
              if (e.toString() === "invalid.customer.ssn") {
                if (inputTAXRef.current) {
                  inputTAXRef.current.focus();
                }
                alert("invalid ssn");
              } else if (e.toString() === "invalid.companyTaxCode") {
                alert("invalid company tax code");
              } else if (e.toString() === "invalid.company.vat") {
                setIsSaving(false);
                setLoading(false);
                if (inputVATRef.current) {
                  inputVATRef.current.focus();
                }
                alert("invalid vat");
              } else {
                setReservationId(e.data.data?.dbId);
                setIsSaving(false);
                switch (selectedPaymentMethod.toLowerCase()) {
                  case "paypal": {
                    payPalFormRef?.current?.submit();
                    break;
                  }
                  // case "nexi": {
                  //   // nexiFormRef?.current?.submit();
                  //   BookingApi.getPaymentLink(
                  //     e.data.data.dbId,
                  //     VehicleDetails.paymentDetails?.price || 0
                  //   ).then((e) => {
                  //     // console.log(e);
                  //     if (e.generateLink) {
                  //       window.open(e.generateLink, "_self");
                  //     }
                  //   });
                  //   break;
                  // }

                  case "nexi": {
                    if (
                      AppSettings?.onrequestPayAmount &&
                      VehicleDetails.vehicleDetails?.Status.toLowerCase() ==
                        "onrequest"
                    ) {
                      BookingApi.getPaymentLink(e.data.data.dbId, 0.01).then(
                        (e) => {
                          if (e.generateLink) {
                            window.open(e.generateLink, "_self");
                          }
                        }
                      );
                    } else {
                      BookingApi.getPaymentLink(
                        e.data.data.dbId,
                        updatedPrice || 0
                      ).then((e) => {
                        if (e.generateLink) {
                          window.open(e.generateLink, "_self");
                        }
                      });
                    }
                    break;
                  }
                  case "wiretransfer": {
                    const dbId = e.data.data?.dbId;
                    if (dbId != undefined) {
                      BookingApi.sendEmailForWireTransfer(
                        e.data.data?.dbId,
                        whetherIfGroupIsOnRequest
                      );
                    }
                    navigateTo(`/booking/confirmed?dbId=${dbId}`); // Append dbId to URL
                    break;
                    // return BookingConfirmPage();
                  }
                  default: {
                    BookingApi.getPaymentLink(
                      e.data.data.dbId,
                      updatedPrice || 0
                    ).then((e) => {
                      // console.log(e);
                      if (e.generateLink) {
                        window.open(e.generateLink, "_self");
                      }
                    });
                    return;
                  }
                }
              }
            });
          }}
          className="grow flex flex-col grow gap-16 box-border p-8 bg-gray-100"
        >
          <div className="bg-white box-border p-8">
            <h2 className="text-xl text-black pb-8 font-bold">
              {t("Personal Details")}
            </h2>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
              {Object.values(formFields).map((el) => {
                if (el.key === "flightNumber") {
                  if (AppSettings?.flightNumber) {
                    return (
                      <AppInputSwitcher
                        {...el}
                        key={el.key}
                        label={translate(el.label || "")}
                        className={"h-20 sm:w-full box-border px-8"}
                        value={
                          userDetails[el.key] || formStates.getValues(el.key)
                        }
                        required={
                          (ReservationDetails.pickUpLocationDetails
                            ?.isAirport ||
                            ReservationDetails?.dropOffLocationDetails
                              ?.isAirport) &&
                          AppSettings?.flightNumberRequired
                            ? true
                            : false
                        }
                        ref={inputTAXRef}
                        onchange={(e) => {
                          formStates.setValue(el.key, e.toString(), {
                            shouldValidate: true,
                          });
                          if (el.key == "country") {
                            handleCountryChange();
                          }
                        }}
                      />
                    );
                  } else {
                    return "";
                  }
                } else {
                  return (
                    <AppInputSwitcher
                      {...el}
                      key={el.key}
                      label={translate(el.label || "")}
                      className={"h-20 sm:w-full box-border px-8"}
                      value={
                        userDetails[el.key] || formStates.getValues(el.key)
                      }
                      onchange={(e) => {
                        formStates.setValue(el.key, e.toString(), {
                          shouldValidate: true,
                        });
                      }}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div className="flex flex-col bg-white box-border p-8   ">
            {/* <div className="">
              <AppInputSwitcher
                label={t("Contract Info")}
                type="text"
                readOnly={true}
                variant="standard"
                className={"h-22 mt-2 mb-2"}
                onchange={() => {}}
                key="info"
                options={[]}
              />
            </div>*/}
            <div className="flex flex-col">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={t("Invoice to company")}
              />
              <AppRadioButton
                required={true}
                value={userType}
                onChange={(e) => {
                  handleUserType(e);
                }}
                options={[
                  {
                    label: t("Individual"),
                    value: "Individual",
                  },
                  {
                    label: t("Company"),
                    value: "Company",
                  },
                ]}
              />
              {checked ? (
                <form
                  onSubmit={handleSubmit((e) => {
                    //console.log(e);

                    onSubmit(e);
                  })}
                  //   className={"flex flex-row flex-wrap"}
                >
                  <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {Object.values(individualFormFields).map((el, index) => {
                      let taxValue = "";
                      let readOnly =
                        el.readOnly ||
                        (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                          !!initialValues[el.key]);
                      const options: RegisterOptions = {
                        required: {
                          value: !!el.required && !readOnly,
                          message: "This is a required field",
                        },
                      };
                      if (el.type === "email") {
                        options.pattern = {
                          value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                          message: "Please provide a valid email",
                        };
                      }
                      if (el.key === "taxCode") {
                        taxValue = taxVal;
                        readOnly = isReadonly;
                      } else {
                        taxValue = "";
                      }
                      console.log("e1" + el.key);
                      return (
                        <>
                          {userType === "Company" &&
                          (el.key === "birthNation" ||
                            el.key === "birthPlace" ||
                            el.key === "dateOfBirth" ||
                            el.key === "birthProvince" ||
                            el.key === "taxCode" ||
                            el.key === "firstName" ||
                            el.key === "lastName" ||
                            el.key === "sex") ? (
                            ""
                          ) : (
                            <div key={index}>
                              <AppInputSwitcher
                                {...el}
                                key={index}
                                autocomplete={false}
                                required={false}
                                {...register(el.key, {
                                  ...options,
                                })}
                                readOnly={readOnly}
                                value={
                                  getValues(el.key) ||
                                  initialValues[el.key] ||
                                  values[el.key] ||
                                  taxValue ||
                                  ""
                                }
                                label={translate(el.label || "")}
                                placeholder={translate(el.placeholder || "")}
                                className={"h-20 box-border px-8"}
                                onchange={(e) => {
                                  const r: any = Object.keys(
                                    CompanyInvoiceFormFields
                                  ).at(index);
                                  if (
                                    el.key == "birthNation" &&
                                    e == "ITALIA"
                                  ) {
                                    setTaxVal("");
                                    setIsReadonly(false);
                                  } else if (
                                    el.key == "birthNation" &&
                                    e != "ITALIA"
                                  ) {
                                    setTaxVal("9999999999999999");
                                    setIsReadonly(true);
                                  }
                                  setCompanyinvoiceDetails({
                                    ...companyinvoiceDetails,
                                    [r]: e,
                                  });

                                  setValue(el.key, e || "", {
                                    shouldValidate: true,
                                  });
                                }}
                              />
                              <FormFieldErrorMessage>
                                {errors[el.key]?.message}
                              </FormFieldErrorMessage>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </div>
                  {children}
                </form>
              ) : (
                ""
              )}
            </div>
            {/*  <div>
              <AppInputSwitcher
                label={t("Info Privacy")}
                type="text"
                variant="standard"
                className={"h-22 mt-2 mb-2"}
                onchange={() => {}}
                key="privacy"
                options={[]}
                readOnly={true}
              />
            </div>*/}
            <div className="flex flex-row flex-wrap gap-8">
              <AppStandardButton
                // clickHandler={() => {
                //   const link = document.createElement("a");
                //   link.download = `privacy policy.pdf`;
                //   link.href = "/assets/t&c/PrivacyPolicy_en.pdf";
                //   link.click();
                // }}
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.termsConditionFile;
                    fetch(url).then(async (e) => {
                      const fileBlob = await e.blob();
                      const linkObj = document.createElement("a");
                      linkObj.href = URL.createObjectURL(fileBlob);
                      linkObj.target = "_blank";
                      linkObj.click();
                      return fileBlob;
                    });
                  });
                }}
                variant="contained"
                color="addExtrasButton"
                style={{ borderRadius: "0" }}
                className="h-12 w-80 mt-8"
              >
                {t("Download terms and conditions")}{" "}
              </AppStandardButton>
              <AppStandardButton
                onClick={() => {
                  BookingApi.getPrivacyAndTerms().then((e) => {
                    const url = e.data.privacyfileUrl;

                    fetch(url).then(async (e) => {
                      const fileBlob = await e.blob();
                      // let file=new File([fileBlob],"test.pdf",{
                      //type: e.type
                      // });

                      const linkObj = document.createElement("a");
                      linkObj.href = URL.createObjectURL(fileBlob);
                      linkObj.target = "_blank";
                      linkObj.click();
                      return fileBlob;
                    });

                    // const link = document.createElement("a");
                    // link.href = e.data.termsConditionFile;
                    // // link.download = e.data.termsConditionFile;
                    // link.target = "_blank";
                    // link.click();
                  });
                }}
                variant="contained"
                color="addExtrasButton"
                style={{ borderRadius: "0" }}
                className="h-12 w-80 mt-8"
              >
                {t("Download the privacy policy")}{" "}
              </AppStandardButton>
            </div>
            <AppStandardMultiSelectCheckBox
              required={true}
              header={t(
                "By clicking you're agreeing to our terms and conditions"
              )}
              options={[
                {
                  label: t("I have read and agree to the rental conditions"),
                  value: "I have read and agree to the rental conditions",
                },
              ]}
            />
          </div>
          {/* this if else added because marauto wants only "pay at desk" payment method if the customer select paylater option */}
          <footer className="flex flex-row flex-wrap bg-white box-border p-8">
            <div className="flex flex-col grow">
              {VehicleDetails.paymentDetails?.type == "PAYNOW" ? (
                <AppRadioButton
                  required={true}
                  label={t("Mode of Payment")}
                  value={selectedPaymentMethod}
                  options={Object.keys(allPaymentMethods)
                    .map((el) => el.toUpperCase())
                    .map((el) => {
                      switch (el) {
                        case "WIRETRANSFER":
                          return {
                            label: t("Pay at desk"),
                            value: "WIRETRANSFER",
                          };
                        case "PAYPAL":
                          return {
                            label: t("PayPal"),
                            value: "PAYPAL",
                          };
                        case "NEXI":
                          return {
                            label: t("Credit card"),
                            value: "NEXI",
                          };
                        default:
                          return {
                            label: "N/A",
                          };
                      }
                    })}
                  onChange={(e) => {
                    setSelectedPaymentMethod(e);
                  }}
                />
              ) : (
                <AppRadioButton
                  required={true}
                  label={t("Mode of Payment")}
                  value={selectedPaymentMethod}
                  options={[
                    {
                      label: t("Credit card"),
                      value: "NEXI",
                    },
                  ]}
                  onChange={(e) => {
                    setSelectedPaymentMethod(e);
                  }}
                />
              )}
            </div>
            <div
              className={`flex flex-row gap-6 ${
                isSmallScreen ? "w-[44%] mt-[8%]" : ""
              }`}
            >
              <AppButton
                loading={isSaving}
                type="submit"
                color="addExtrasButton"
                className="h-12 w-44 flex justify-center rounded-sm"
              >
                <span className="flex text-center w-full h-full mt-3 justify-center">
                  {t("Confirm")}
                </span>
              </AppButton>
            </div>
            <span className=" text-black">{t("Payment Subtitle")}</span>
            <div className="flex flex-row  text-[#707070] mt-4 mb-2">
              {AppSettings?.onrequestPayAmount &&
              VehicleDetails.vehicleDetails?.Status.toLowerCase() == "onrequest"
                ? t(
                    "The reservation will be subjected to confirmation – during the payment process, you will be asked to pay €0.01 and, once the reservation is confirmed, you will be charged the total amount of the rental on the registered credit card."
                  )
                : ""}
            </div>
          </footer>
        </form>
      </div>
      <div className={"text-black hidden"}>
        {allPaymentMethods?.paypal && allPaymentMethods?.paypal?.length > 0 && (
          <PayPal
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={payPalFormRef}
            postUrl={allPaymentMethods.paypal[0]?.sandboxPaymentURL || ""}
            companyEmail={
              allPaymentMethods.paypal[0]?.sandboxEmailCompany || ""
            }
            amount={updatedPrice || 0}
            returnLink={allPaymentMethods.paypal[0]?.sandboxReturnURL || ""}
            notify_url={allPaymentMethods.paypal[0]?.sandboxNotifyURL || ""}
            cancel_url={allPaymentMethods.paypal[0]?.sandboxCancelURL || ""}
            itemName={"NAME HERE"}
            reservationId={ReservationId}
          />
        )}
        {/* {allPaymentMethods && "nexi" in allPaymentMethods && (
          <Nexi
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={nexiFormRef}
            postUrl={allPaymentMethods.nexi[0]?.customAttribute1 || ""}
            // companyEmail={allPaymentMethods.nexi[0]?.fieldName || ""}
            amount={paymentDetails?.price || 0}
            returnLink={"https://www.google.com"}
            notify_url={"https://www.youtube.com"}
            cancel_url={"https://www.test.com"}
            itemName={"NAME HERE"}
          />
        )} */}
      </div>
    </section>
  );
}
export { PaymentDetailsPage };
export const isBookingForm = true;
