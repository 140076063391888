import "./App.css";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { BasePageLayout } from "./components/Layouts/BasePage";
import { Router } from "./router/router";
import { useDispatch } from "react-redux";
import { PageConfigActions } from "./store/PageConfigurations";
import React, { useEffect, useState } from "react";
import BookingApi from "./API/Apis/BookingApi";
import { BOOKING_ACTIONS } from "./store/onlineBooking";
import i18n from "./config/i18n";
import TagManager from "react-gtm-module";

function App() {
  const dispatch = useDispatch();
  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);
  useEffect(() => {
    dispatch(PageConfigActions.setHomePageConfig({}));
    dispatch(PageConfigActions.setHeaderConfig({}));
    dispatch(PageConfigActions.setFooterConfig({}));
    BookingApi.getAppSetting().then((e) => {
      setIsTagManagerEnabled(e.enableTagManager);
      setTagManagerId(e.tagManagerId ? e.tagManagerId : "");
      dispatch(BOOKING_ACTIONS.setAppSetting(e));
    });
    BookingApi.getPrivacyAndTerms().then((e) => {
      dispatch(BOOKING_ACTIONS.setRentalTerms(e.data.conditionText));
    });
  }, []);
  const [queryParams, setQueryParams] = useSearchParams();
  const [currentLanguage, setCurrentLanguage] = useState<string>(i18n.language);
  useEffect(() => {
    const currentLanguage = queryParams.get("lang");
    if (currentLanguage && i18n.languages.includes(currentLanguage)) {
      i18n.changeLanguage(currentLanguage);
      setCurrentLanguage(currentLanguage);
    }
    // console.log(currentLanguage);
  }, []);
  return (
    <BasePageLayout>
      <div>
        <Routes>
          {Router.map((el, index) => {
            let shouldRedirect = false;
            let redirectUrl = "/";
            if (el.shouldRedirect) {
              shouldRedirect = el.shouldRedirect();
            }
            if (el.redirectUrl) {
              redirectUrl = el.redirectUrl;
            }
            return (
              <Route
                key={index}
                path={el.path}
                element={
                  <ProtectedRoute
                    shouldRedirect={shouldRedirect}
                    url={redirectUrl}
                    child={el.element}
                  ></ProtectedRoute>
                }
              >
                {el.children &&
                  el.children.map((nested, id) => {
                    return (
                      <Route
                        key={id}
                        path={nested.path}
                        element={
                          <ProtectedRoute
                            shouldRedirect={shouldRedirect}
                            url={redirectUrl}
                            child={nested.element}
                          />
                        }
                      />
                    );
                  })}
              </Route>
            );
          })}
        </Routes>
      </div>
    </BasePageLayout>
  );
}
interface ProtectedRouteProps {
  child: Element | JSX.Element;
  shouldRedirect: boolean;
  url: string;
  children?: any;
}

function ProtectedRoute({
  child,
  shouldRedirect,
  url = "/",
}: ProtectedRouteProps): JSX.Element {
  if (shouldRedirect) {
    return <Navigate to={url} />;
  }
  return <>{child}</>;
}
export default App;
