import { Dialog, DialogContent } from "@mui/material";

import AppButtons from "../AppButtons";
import { AppModalProps } from "./AppModal.props";
import { CloseOutlined as CloseButtonIcon } from "@mui/icons-material";
import React from "react";

function AppModal({ children, handleClose, isOpen }: AppModalProps) {
  return (
    <div style={{ position: "fixed" }}>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose(true);
          }
        }}
        open={isOpen}
        maxWidth="md"
        fullWidth
        //  onBackdropClick={handleClose}
      >
        <DialogContent>
          <div className="flex flex-col h-full">
            <header
              className={
                "sticky top-0 h-full bg-white w-full float-right flex justify-end"
              }
            >
              <AppButtons
                disableElevation
                startIcon={<CloseButtonIcon />}
                color="clear"
                className="w-fit"
                clickHandler={handleClose}
              >
                <></>
              </AppButtons>
            </header>
            <main className={"h-full"}>
              <div className={"h-full overflow-scroll "}>{children}</div>
            </main>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
export { AppModal };
