import * as React from "react";

import {
  DefaultValuesDetailsForm,
  DetailsFormValues,
} from "../WebCheckInForms/WebCheckInForms.props";

import { AppInputSwitchProps } from "../AppInputSwitcher/AppInputSwitcher.props";
import { AppInputSwitcher } from "../AppInputSwitcher/AppInputSwitcher";
import { DriverFormFields } from "../WebCheckInForms/FormKeys";
import { DriverFormProps } from "./DriverFormProps";
import { FormFieldErrorMessage } from "../../UI/FormFieldErrorMessage/FormFieldErrorMessage";
import { ReadOnlyIfInitialValuesNotProvided } from "../WebCheckInForms/FormOptions";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useTranslation } from "react-i18next";
import { useWebCheckInForm } from "../../../hooks/WebCheckInForm_2";
import { AddDocumentsWithPreview } from "../../UI/AllWebCheckInForms/DocumentUpload";
import { t } from "i18next";

export function DriverAddForm({
  driverDetails = {},
  initialValues = { ...DefaultValuesDetailsForm },

  onChange = () => {},
  onSubmit = () => {},
  children = <></>,
}: DriverFormProps) {
  const [
    {
      register,
      handleSubmit,
      formState: { errors },
      getValues,
      setValue,
    },
    driverFormFields,
  ] = useWebCheckInForm(
    DriverFormFields,
    {
      ...Object.keys(initialValues).reduce((prevValue, currentKey) => {
        prevValue[currentKey] = initialValues[currentKey]
          ? initialValues[currentKey]
          : driverDetails[currentKey] || DefaultValuesDetailsForm[currentKey];

        return prevValue;
      }, {}),
    } as DetailsFormValues,
    (e) => {
      onChange({ ...DefaultValuesDetailsForm, ...e });
    }
  );
  const handleCountryChange = (newCountryValue) => {
    setValue("country", newCountryValue || "");
    setValue("city", "");
    setValue("state", "");
  };
  const { t: translate } = useTranslation();
  return (
    <>
      <form
        onSubmit={handleSubmit((e) => {
          //  console.log(e);
          setTimeout(onSubmit, 1000, { ...driverDetails, ...e });
        })}
      >
        <div className={"grid grid-cols-2 gap-4 mt-2"}>
          {Object.values(driverFormFields).map((el: AppInputSwitchProps) => {
            const readOnly =
              el.readOnly ||
              (ReadOnlyIfInitialValuesNotProvided.includes(el.key) &&
                !!initialValues[el.key]);
            const options: RegisterOptions = {
              required: {
                value: !!el.required,
                message: "This is a required field",
              },
            };
            if (el.type === "email") {
              options.pattern = {
                value: /^[a-zA-Z\d+_.-]+@[a-zA-Z\d.-]+$/,
                message: "Please provide a valid email",
              };
            }
            if (el.key === driverDetails?.ssn?.key) {
              options.maxLength = {
                value: 16,
                message: "Please provide a valid SSN",
              };
              options.minLength = {
                value: 16,
                message: "Please provide a valid SSN",
              };
            }
            return (
              <div className="w-full h-full" key={el.key}>
                {/* ===={initialValues[el.key]}
              <br />
              {getValues(el.key)}
              <br />
              {driverDetails[el.key]} */}
                <AppInputSwitcher
                  {...el}
                  {...register(el.key, {
                    ...options,
                  })}
                  required={false}
                  onChange={() => {}}
                  className="h-[4.3rem]"
                  label={translate(el.label || "")}
                  readOnly={readOnly}
                  placeholder={translate(el.placeholder || "")}
                  options={el.options || []}
                  value={getValues(el.key) || ""}
                  onchange={(e) => {
                    if (el.key == "country") {
                      handleCountryChange(e);
                    }
                    setValue(el.key, e, {
                      // shouldValidate: true,
                    });
                  }}
                />
                <FormFieldErrorMessage>
                  {errors[el.key]?.message}
                </FormFieldErrorMessage>
              </div>
            );
          })}
        </div>

        {/* {Adding document inside driver form} */}
        {/* <p className="text-base font-semibold margin"> 
          {t("Please add the driver documents")}
        </p>
        <AddDocumentsWithPreview
          onChange={(document) => {
            setValue("documents", document, {
              shouldValidate: true,
            });
          }}
          selectedDocs={getValues("documents") || []}
        /> */}
        {children}
      </form>
    </>
  );
}
