import { useEffect, useState } from "react";

import { ChosenOptions } from "../BookingVehicleInfoCard/BookingVehicleInfoCard.props";
import React from "react";
import { SelectVehicleExtrasProps } from "./SelectVehicleExtras.props";
import { SelectedVehicleExtraOptions } from "../../../@types/VehicleOptions";
import VehicleExtraOptions from "../../UI/VehicleExtraOptions";

SelectVehicleExtras.defaultProps = {
  onChange: () => {},
};
function SelectVehicleExtras({
  options,
  onChange,
  insuranceList,
  values = {},
}: SelectVehicleExtrasProps): JSX.Element {
  let isElementInInsurance = false;
  const [selectedOptions, setSelectedOptions] =
    useState<ChosenOptions["options"]>(values);
  const isSmallScreen = window.innerWidth <= 768;
  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions, insuranceList]);
  return (
    <div className="flex md:flex-row md:flex-wrap flex-col h-full ">
      {options.map((el, index) => (
        <div key={index} className="mt-2">
          {insuranceList["Booking Insurance"].some((e) => {
            if (e.optionalsCode === el.optionName) {
              isElementInInsurance = true;
              return true;
            } else {
              isElementInInsurance = false;
              return false;
            }
          })}
          {!isElementInInsurance ? (
            <div
              style={isSmallScreen ? { width: "230px" } : {}}
              className="md:w-56 w-full aspect-square h-[9rem] border-solid border-2 border-gray-300 box-border p-2 mx-4 flex"
            >
              <VehicleExtraOptions
                onDecrementClick={() => {
                  setSelectedOptions((prev) => {
                    const optionName = el.optionName.trim();
                    if (!prev[optionName]) {
                      return { ...prev };
                    }
                    let quantity = prev[optionName].quantity;
                    quantity--;
                    prev[optionName].quantity = Math.max(quantity, 0);
                    prev[optionName].name = el.optionName.trim();
                    prev[optionName].price = el.pricePerDayInUSD;
                    if (!prev[optionName].quantity) {
                      delete prev[optionName];
                    }
                    return { ...prev };
                  });
                }}
                onIncrementClick={() => {
                  setSelectedOptions((prev) => {
                    let maxAllowed: number = 1;
                    const optionName = el.optionName.trim();
                    if (el.maxAllowed) {
                      maxAllowed = el.maxAllowed;
                    }
                    if (!(prev[optionName] && prev[optionName].quantity)) {
                      prev[optionName] = {};
                      prev[optionName].name = optionName;
                      prev[optionName].quantity = 0;
                      prev[optionName].price = options[index].pricePerDayInUSD;
                    }

                    let quantity = prev[optionName].quantity;
                    quantity++;
                    prev[optionName].quantity = Math.min(quantity, maxAllowed);
                    return { ...prev };
                  });
                }}
                {...el}
                value={
                  selectedOptions[el.optionName.trim()]
                    ? selectedOptions[el.optionName.trim()].quantity || 0
                    : 0
                }
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ))}
    </div>
  );
}
export { SelectVehicleExtras as default };
export { SelectVehicleExtras };
